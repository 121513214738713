import {
  GET_LIBRARY_FORMS,
  GET_LIBRARY_FORMS_SUCCESS,
  GET_LIBRARY_FORMS_ERROR,
  GET_LIBRARY_FORM_STATUSES,
  GET_LIBRARY_FORM_STATUSES_SUCCESS,
  GET_LIBRARY_FORM_STATUSES_ERROR,
  ADD_LIBRARY_FORM_CUSTOM_FIELD,
  EDIT_LIBRARY_FORM_CUSTOM_FIELD,
  DELETE_LIBRARY_FORM_CUSTOM_FIELD,
  GET_LIBRARY_FORMS_COUNT,
  GET_LIBRARY_FORMS_COUNT_SUCCESS,
  GET_LIBRARY_FORMS_COUNT_ERROR,
} from '../actions/formsLibraryActions';

const initialValue = {
  pagination: {
    0: null,
  },
  items: [],
  formContainers: {},
  count: 0,
  isFetching: false,
  error: null,
};

export default function (state = initialValue, action) {
  switch (action.type) {
    case GET_LIBRARY_FORMS: {
      return {
        ...state,
        formContainers: {},
      };
    }
    case GET_LIBRARY_FORMS_SUCCESS: {
      const data = action.payload || [];
      const formAttachments = data.reduce((acc, num) => {
        acc[num._id] = num.attachments;
        return acc;
      }, {});

      return {
        ...state,
        formContainers: formAttachments,
      };
    }
    case GET_LIBRARY_FORMS_ERROR: {
      return state;
    }
    case GET_LIBRARY_FORMS_COUNT: {
      return {
        ...state,
        count: 0,
      };
    }
    case GET_LIBRARY_FORMS_COUNT_SUCCESS: {
      return {
        ...state,
        count: action.payload,
      };
    }
    case GET_LIBRARY_FORMS_COUNT_ERROR: {
      return {
        ...state,
        count: 0,
      };
    }
    case GET_LIBRARY_FORM_STATUSES: {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    }
    case GET_LIBRARY_FORM_STATUSES_SUCCESS: {
      const { items, currentPage, lastItemId } = action.payload;
      return {
        ...state,
        pagination: {
          ...currentPage !== 0 && { ...state.pagination },
          [currentPage]: lastItemId,
        },
        items,
        isFetching: false,
        error: null,
      };
    }
    case GET_LIBRARY_FORM_STATUSES_ERROR: {
      return {
        ...state,
        count: 0,
        items: [],
        isFetching: false,
        error: action.payload,
      };
    }
    case ADD_LIBRARY_FORM_CUSTOM_FIELD: {
      if (!state.items.length) {
        return state;
      }

      const {
        formId,
        formStatusId,
        data,
      } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === formId) {
            return {
              ...item,
              statuses: item.statuses.map(formStatus => {
                if (formStatus._id === formStatusId) {
                  return {
                    ...formStatus,
                    customFields: [...formStatus.customFields, data],
                  };
                }
                return formStatus;
              }),
            };
          }
          return item;
        }),
      };
    }
    case EDIT_LIBRARY_FORM_CUSTOM_FIELD: {
      if (!state.items.length) {
        return state;
      }

      const {
        formId,
        formStatusId,
        customFieldRecordId,
        data,
      } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === formId) {
            return {
              ...item,
              statuses: item.statuses.map(formStatus => {
                if (formStatus._id === formStatusId) {
                  return {
                    ...formStatus,
                    customFields: formStatus
                      .customFields
                      .map(customField => {
                        if (customField._id === customFieldRecordId) {
                          return data;
                        }
                        return customField;
                      }),
                  };
                }
                return formStatus;
              }),
            };
          }
          return item;
        }),
      };
    }
    case DELETE_LIBRARY_FORM_CUSTOM_FIELD: {
      if (!state.items.length) {
        return state;
      }

      const {
        formId,
        formStatusId,
        customFieldRecordId,
      } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === formId) {
            return {
              ...item,
              statuses: item.statuses.map(formStatus => {
                if (formStatus._id === formStatusId) {
                  return {
                    ...formStatus,
                    customFields: formStatus
                      .customFields
                      .filter(customField => customField._id !== customFieldRecordId),
                  };
                }
                return formStatus;
              }),
            };
          }
          return item;
        }),
      };
    }
    default:
      return state;
  }
}
