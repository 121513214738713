import axios from 'axios';
import { setAuthToken } from './index';

const LIBRARY_URL = `${process.env.REACT_APP_API_ENDPOINT}/library`;

export const GET_LIBRARY_RATES = 'GET_LIBRARY_RATES';
export const GET_LIBRARY_RATES_SUCCESS = 'GET_LIBRARY_RATES_SUCCESS';
export const GET_LIBRARY_RATES_ERROR = 'GET_LIBRARY_RATES_ERROR';

export const GET_LIBRARY_RATE_STATUSES = 'GET_LIBRARY_RATE_STATUSES';
export const GET_LIBRARY_RATE_STATUSES_SUCCESS = 'GET_LIBRARY_RATE_STATUSES_SUCCESS';
export const GET_LIBRARY_RATE_STATUSES_ERROR = 'GET_LIBRARY_RATE_STATUSES_ERROR';

export const GET_LIBRARY_RATES_COUNT = 'GET_LIBRARY_RATES_COUNT';
export const GET_LIBRARY_RATES_COUNT_SUCCESS = 'GET_LIBRARY_RATES_COUNT_SUCCESS';
export const GET_LIBRARY_RATES_COUNT_ERROR = 'GET_LIBRARY_RATES_COUNT_ERROR';

export const GET_FILING_LIBRARY_RATE_STATUSES = 'GET_FILING_LIBRARY_RATE_STATUSES';

export const ADD_LIBRARY_RATE_CUSTOM_FIELD = 'ADD_LIBRARY_RATE_CUSTOM_FIELD';
export const EDIT_LIBRARY_RATE_CUSTOM_FIELD = 'EDIT_LIBRARY_RATE_CUSTOM_FIELD';
export const DELETE_LIBRARY_RATE_CUSTOM_FIELD = 'DELETE_LIBRARY_RATE_CUSTOM_FIELD';

export const getLibraryRates = (ids) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_LIBRARY_RATES });
      const res = await axios.get(`${LIBRARY_URL}/library-rates`, {
        params: { ids },
      });
      dispatch({
        type: GET_LIBRARY_RATES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_LIBRARY_RATES_ERROR,
        payload: response && response.data,
      });
      throw response.data;
    }
  }
}

export const getLibraryRateStatuses = (params, currentPage) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_LIBRARY_RATE_STATUSES });
      const res = await axios.post(`${LIBRARY_URL}/library-rate-statuses/search`, params);
      const rateStatusList = res?.data?.items || [];

      if (rateStatusList) {
        const groupedStatuses = Object
          .groupBy(rateStatusList, ({ libraryRateId }) => libraryRateId);

        const rates = Object.keys(groupedStatuses).reduce((acc, num) => {
          const { rateName, ruleNumber } = groupedStatuses[num][0];
          acc.push({
            _id: num,
            statuses: groupedStatuses[num],
            rateName,
            ruleNumber,
            attachments: [],
          });
          return acc;
        }, []);

        dispatch({
          type: GET_LIBRARY_RATE_STATUSES_SUCCESS,
          payload: {
            items: rates,
            currentPage,
            lastItemId: rateStatusList[rateStatusList.length - 1]?._id,
          },
        });

        return rates;
      }
    } catch ({ response }) {
      dispatch({
        type: GET_LIBRARY_RATE_STATUSES_ERROR,
        payload: response && response.data,
      });
      throw response.data;
    }
  };
};

export const getLibraryRatesCount = (params) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_LIBRARY_RATES_COUNT,
      });
      const res = await axios.post(`${LIBRARY_URL}/library-rate-statuses/count-grouped`, params);
      dispatch({
        type: GET_LIBRARY_RATES_COUNT_SUCCESS,
        payload: res.data?.count || 0,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_LIBRARY_RATES_COUNT_ERROR,
      });
      throw response.data;
    }
  }
}

export const editLibraryRateStatusBulk = (payload) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-rate-statuses/bulk`, payload);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryRateStatusCustomFieldsBulk = (payload) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-rate-statuses/custom-fields/bulk`, payload);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryRateStatusCustomFieldsBulk = (payload) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rate-statuses/custom-fields/bulk`, payload);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteLibraryRateStatusCustomFieldsBulk = (payload) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-rate-statuses/custom-fields/bulk`, {
        data: payload,
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const scanLibraryRates = () => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rate-statuses/scan`);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addRateStatusReplaces = ({ rateId, statusId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rate-statuses/${statusId}/replaces`, data);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteRateStatusReplaces = ({ rateid, statusId, id }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-rate-statuses/${statusId}/replaces/${id}`);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryRateStatusCustomField = ({
  rateId,
  rateStatusId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rate-statuses/${rateStatusId}/custom-fields`, data);
      dispatch({
        type: ADD_LIBRARY_RATE_CUSTOM_FIELD,
        payload: {
          rateId,
          rateStatusId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryRateStatusCustomField = ({
  rateId,
  rateStatusId,
  customFieldRecordId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-rate-statuses/${rateStatusId}/custom-fields/${customFieldRecordId}`, data);
      dispatch({
        type: EDIT_LIBRARY_RATE_CUSTOM_FIELD,
        payload: {
          rateId,
          rateStatusId,
          customFieldRecordId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteLibraryRateStatusCustomField = ({
  rateId,
  rateStatusId,
  customFieldRecordId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-rate-statuses/${rateStatusId}/custom-fields/${customFieldRecordId}`);
      dispatch({
        type: DELETE_LIBRARY_RATE_CUSTOM_FIELD,
        payload: {
          rateId,
          rateStatusId,
          customFieldRecordId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryRateStatus = (statusId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-rate-statuses/${statusId}`, data);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const cloneLibraryRateStatus = (statusId) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rate-statuses/${statusId}/clone`);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryRateStatus = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rate-statuses`, data);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteLibraryRateAttachment = ({ rateId, attachmentId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-rates/${rateId}/attachments/${attachmentId}`);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryRateAttachment = ({
  rateId,
  attachmentId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-rates/${rateId}/attachments/${attachmentId}`, data);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryRateAttachment = ({ rateId, attachment }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rates/${rateId}/attachments`, attachment);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryRate = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rates`, data);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const getLibraryRate = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${LIBRARY_URL}/library-rates/${id}`);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const getLibraryRateMap = ({
  rateName,
  ruleNumber,
  archived,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${LIBRARY_URL}/library-rate-statuses/map`, {
        params: {
          rateName,
          ruleNumber,
          archived,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const getStatusesByRateId = (params) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${LIBRARY_URL}/library-rate-statuses`, {
        params,
      });
      return res?.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};
